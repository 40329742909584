<!--
 * @Description: 首页组件
 * @Author: banbri
 * @Date: 2021-02-07 16:23:00
 * @LastEditors: Banbri
 * @LastEditTime: 2021-06-09 15:08:25
 -->
<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="460px">
        <el-carousel-item v-for="item in carousel" :key="item.carousel_id">
          <img style="height:460px;" :src="$target + item.imgPath" :alt="item.describes"  @click="goToDetail(item.product_id)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图END -->
    <div class="main-box">
      <div class="main">
        <!-- 收纳柜商品展示区域 -->
        <div>
          <div class="box-hd">
            <div class="title">收纳柜</div>
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <router-link to>
                <img :src="$target +'public/imgs/cupboard/五斗柜.jpg'" />
              </router-link>
            </div>
            <div class="list">
              <MyList :list="cupList" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 收纳柜商品展示区域END -->

        <!-- 收纳盒商品展示区域 -->
        <div class="box" id="promo-menu">
          <div class="box-hd">
            <div class="title">收纳盒</div>
            <div class="more" id="more">
              <MyMenu :val="2" @fromChild="getChildMsg">
                <span slot="1">热门</span>
                <span slot="2">特色收纳盒</span>
              </MyMenu>
            </div>
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <ul>
                <li>
                  <img :src="$target +'public/imgs/box/box1.jpg'" />
                </li>
                <li>
                  <img :src="$target +'public/imgs/box/box2.jpg'" />
                </li>
              </ul>
            </div>
            <div class="list">
              <MyList :list="boxList" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 收纳盒商品展示区域END -->

        <!-- 收纳架商品展示区域 -->
        <div class="shelf" id="promo-menu">
          <div class="box-hd">
            <div class="title">收纳架</div>
            <div class="more" id="more">
              <MyMenu :val="3" @fromChild="getChildMsg2">
                <span slot="1">热门</span>
                <span slot="2">厨房收纳架</span>
                <span slot="3">客厅收纳架</span>
              </MyMenu>
            </div>
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <ul>
                <li>
                  <img :src="$target +'public/imgs/shelf/shelf1.jpg'" alt />
                </li>
                <li>
                  <img :src="$target +'public/imgs/shelf/shelf2.jpg'" alt />
                </li>
              </ul>
            </div>
            <div class="list">
              <MyList :list="shelfList" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 收纳架商品展示区域END -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carousel: "", // 轮播图数据
      cupList: "", // 收纳柜商品列表
      sboxList: "", // 本特色收纳盒商品列表
      boxList: "", // 收纳盒商品列表
      boxHotList: "", // 热门收纳盒商品列表
      shelfList: "", // 收纳架商品列表
      shelfHotList: "", // 热门收纳架商品列表
      kitShelfList: "", // 厨房收纳架商品列表
      parShelfList: "", // 客厅收纳架商品列表
      boxActive: 1, // 收纳盒当前选中的商品分类
      shelfActive: 1 // 收纳架当前选中的商品分类
    };
  },
  watch: {
    // 收纳盒当前选中的商品分类，响应不同的商品数据
    boxActive: function(val) {
      // 页面初始化的时候把boxHotList(热门收纳盒商品列表)直接赋值给boxList(收纳盒商品列表)
      // 所以在切换商品列表时判断boxHotList是否为空,为空则是第一次切换,把boxList赋值给boxHotList
      if (this.boxHotList == "") {
        this.boxHotList = this.boxList;
      }
      if (val == 1) {
        // 1为热门商品
        this.boxList = this.boxHotList;
        return;
      }
      if (val == 2) {
        // 2为收纳盒商品
        this.boxList = this.sboxList;
        return;
      }
    },
    shelfActive: function(val) {
      // 页面初始化的时候把shelfHotList(热门收纳架商品列表)直接赋值给shelfList(收纳架商品列表)
      // 所以在切换商品列表时判断shelfHotList是否为空,为空则是第一次切换,把shelfList赋值给shelfHotList
      if (this.shelfHotList == "") {
        this.shelfHotList = this.shelfList;
      }
      if (val == 1) {
        // 1为热门商品
        this.shelfList = this.shelfHotList;
        return;
      }
      if (val == 2) {
        // 2为厨房收纳架商品
        this.shelfList = this.kitShelfList;
        return;
      }
      if (val == 3) {
        //3 为客厅收纳架商品
        this.shelfList = this.parShelfList;
        return;
      }
    }
  },
  created() {
    // 获取轮播图数据
    this.$axios
      .post("/api/resources/carousel", {})
      .then(res => {
        this.carousel = res.data.carousel;
      })
      .catch(err => {
        return Promise.reject(err);
      });
    // 获取各类商品数据
    this.getPromo("收纳柜", "cupList");
    this.getPromo("特色收纳盒", "sboxList");
    this.getPromo("厨房收纳架", "kitShelfList");
    this.getPromo("客厅收纳架", "parShelfList");
    this.getPromo(
      ["特色收纳盒", "浴室用品", "化妆品收纳"],
      "boxList",
      "/api/product/getHotProduct"
    );
    this.getPromo(
      ["厨房收纳架", "卧室收纳架", "客厅收纳架", "阳台收纳架"],
      "shelfList",
      "/api/product/getHotProduct"
    );
  },
  methods: {
    // 获取收纳盒模块子组件传过来的数据
    getChildMsg(val) {
      this.boxActive = val;
    },
    // 获取收纳架模块子组件传过来的数据
    getChildMsg2(val) {
      this.shelfActive = val;
    },
    // 获取各类商品数据方法封装
    getPromo(categoryName, val, api) {
      api = api != undefined ? api : "/api/product/getPromoProduct";
      this.$axios
        .post(api, {
          categoryName
        })
        .then(res => {
          this[val] = res.data.Product;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    goToDetail(product_id){
      this.$router.push({name:'Details',query:{productID:product_id}})
    }
  }
};
</script>
<style scoped>
@import "../assets/css/index.css";
</style>